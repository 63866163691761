<template>
  <div class="title touch-enable" @click="$emit('click')">
    <div class="title-box">
      <img class="itim_is" src="../../assets/images/title.png" alt="" />
      <span class="title-text">{{ title }}</span>
    </div>
    <div class="btn_cont" @click="set_show()" v-if="title2">{{ title2 }}</div>
    <!-- <slot></slot> -->
       <img
      v-if="borders"
      class="border_bottoms"
      src="../../assets/images/title_border1.png"
      alt=""
    />
    <img
      v-else
      class="border_bottoms"
      src="../../assets/images/title_border.png"
      alt=""
    />
  
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    title2: {
      type: String,
      default: "",
    },
    borders: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    set_show(){
      this.$emit('set_show')
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  position: relative;
  width: px2vw(417);
  height: px2vh(42);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-box {
    display: flex;
    .itim_is {
      width: px2vw(16);
      height: px2vh(30);
    }
    .title-text {
      font-size: px2vh(24);
      line-height: px2vh(31);
      color: #ffffff;
      font-family: "ysbth";
      font-weight: 400;
      margin-left: px2vw(6);
    }
  }
  .btn_cont {
    width: px2vw(96);
    height: px2vh(32);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/component_img/btn_bg.png");
    background-size: 100% 100%;
    color: #8efffc;
    font-size: px2vh(16);
    font-family: "ysbth";
    cursor: pointer;
  }
  .border_bottoms {
    position: absolute;
    left: 0;
    bottom: 0;
    width: px2vw(417);
    height: px2vh(4);
  }
}
</style>
