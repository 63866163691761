import _ from 'lodash';
import user from './user.js'

function createStore(Vue) {
  const store = new Vue({
    data() {
      return {
        state: {},
        modules: {
          user
        }
      }
    },
    methods: {
      set(key, value) {
        this.$set(this.state, key, value);
      },
      get(props) {
        return _.get(this.state, props);
      },
      registerModule(name, module) {
        this.$set(this.modules, name, module);
        this.$set(this.state, name, module.state || {});
      },
      dispatch(actionPath, payload) {
        const [moduleName, actionName] = actionPath.split('/');
        const module = this.modules[moduleName];
        if (module && module.actions && module.actions[actionName]) {
          return Promise.resolve(module.actions[actionName](this, payload));
        }
        return Promise.reject(new Error(`Action ${actionPath} not found`));
      }
    }
  });

  return store;
}


export default {
  install(Vue) {
    const store = createStore(Vue);
    window.vueStore = store;
    Vue.prototype.$store = store;
  }
};
